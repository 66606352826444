import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form';
import {
  FormStyles,
  SubscribeContainer,
} from '../../styles/Components/Forms/FormStyles';
import setValuesFromParams from './utils/setValuesFromParams';

const SubscribeForm = ({ block = false, heading, hubspotFormId: formId }) => {
  const hubspotFormId = formId || '92b6b20c-fae9-4766-992f-3cbaec4dcb8e';
  const hubspotPortalId = '5424293';
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [utmParameters, setParameters] = useState({});

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setParameters({
        utm_campaign: window.sessionStorage.getItem('utm_campaign'),
        utm_source: window.sessionStorage.getItem('utm_source'),
        utm_medium: window.sessionStorage.getItem('utm_medium'),
        utm_term: window.sessionStorage.getItem('utm_term'),
        utm_content: window.sessionStorage.getItem('utm_content'),
        google_click_id: window.sessionStorage.getItem('gclid'),
      });
    }
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`;
      const fields = [];
      Object.keys(utmParameters).forEach(key => {
        if (utmParameters[key] != null) {
          fields.push({ name: key, value: utmParameters[key] });
        }
      });

      fields.push({ name: `email`, value: `${email}` });

      const postData = {
        submittedAt: Date.now(),
        fields,
        context: {
          pageUri: `https://www.shopmonkey.io/blog`,
          pageName: `Blog`,
        },
      };

      axios
        .post(url, postData)
        .then(() => setSubscribed(true))
        .catch(err => console.log(`Blog Form Submission Failed - ${err}`));
    },
    [hubspotPortalId, hubspotFormId, utmParameters, email]
  );

  const handleChange = e => {
    setEmail(e.target.value);
  };

  return (
    <FormStyles className={`subscribe-form${block ? ' block' : ''}`}>
      <SubscribeContainer>
        {!subscribed ? (
          <div className="form-wrapper">
            <div className="form-header">
              <h2>
                {heading ||
                  'Get access to the best advice in auto shop management'}
              </h2>
            </div>
            <div className="form-content">
              <Form id="subscribe-form" onSubmit={e => handleSubmit(e)}>
                <div id="hub-form" className="d-none">
                  <HubspotForm
                    formId={hubspotFormId}
                    onFormReady={setValuesFromParams}
                    onSubmit={() => setSubscribed(true)}
                    portalId={hubspotPortalId}
                  />
                </div>
                <div className="form-inputs">
                  <Form.Control
                    required
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    className="light-input"
                    placeholder="Email Address"
                    onChange={e => handleChange(e)}
                  />
                  <Button variant="primary" type="submit">
                    Subscribe
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="thank-header">Thank you!</h3>
            <p>
              You will now be notified whenever great shop management content
              comes out.
            </p>
          </div>
        )}
      </SubscribeContainer>
    </FormStyles>
  );
};

export default SubscribeForm;
