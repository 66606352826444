import styled from 'styled-components';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';
import { atMinWidth } from '../../atoms/breakpoints';

export const FormStyles = styled.div`
  @media (max-width: 991.98px) {
    margin-top: 24px;
    margin-bottom: 50px;
  }

  &.subscribe-form {
    @media (max-width: 991.98px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.block {
      margin: 32px 0;
    }
  }

  &.hero-form {
    background: ${colors.base.white};
    padding: 48px;
    box-shadow: 0px 16px 80px rgba(106, 114, 158, 0.2);
    border-radius: 12px;
    min-height: 470px;

    @media (max-width: 768px) {
      padding: 24px;
    }
  }

  .form-title {
    font-weight: 700;
  }

  .form-group {
    margin-bottom: 24px;

    button {
      letter-spacing: 1.4px;
    }
  }
  h4 {
    font-weight: bold;
    letter-spacing: -0.4px;
    margin-bottom: 32px;
  }

  input,
  select {
    font-size: 18px;
    height: 48px;

    &::placeholder {
      color: #bdbdbd;
    }

    & + label {
      color: #bdbdbd;
    }

    &:focus {
      color: #495057;
      border-color: #1c3fff;
      outline: 0;
      box-shadow: none;

      & + label {
        color: #1c3fff;
      }
    }

    &.light-input {
      background-color: transparent;
      color: ${colors.base.white};
      border-color: ${colors.base.white};

      &:focus {
        color: ${colors.base.white};
        border-color: ${colors.base.white};
      }
    }
  }

  .form-group {
    position: relative;
    margin: 0 0 18px;
    padding: 0 !important;

    .form-label.material-label {
      position: absolute;
      z-index: 0;
      top: 11px;
      left: 8px;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transform-origin: left top;
      pointer-events: none;
      font-size: 16px;
      padding: 0 8px;
    }

    .form-control {
      border-radius: 3px;
      padding: 0.5rem 0.88rem;
      height: 47px;

      &.btr-0 {
        border-top-right-radius: 0 !important;
      }

      &.btl-0 {
        border-top-left-radius: 0 !important;
      }

      &.bbr-0 {
        border-bottom-right-radius: 0 !important;
      }

      &.bbl-0 {
        border-bottom-left-radius: 0 !important;
      }

      &:focus {
        box-shadow: none !important;
      }

      &:valid + .material-label,
      &:focus + .material-label,
      & + .material-label.filled {
        transform: translate(0, -22px) scale(0.75);
        background: ${colors.base.white};
        padding: 2px 10px;
        z-index: 20;

        &.light-label {
          background: var(--primary-color);
          color: ${colors.base.white};
        }
      }
    }

    .invalid-feedback {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    & + .material-label {
      color: #dc3545;
    }
  }

  .form-select {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      top: 22px;
      right: 18px;
      border: 4px solid transparent;
      border-top-color: #425466;
    }

    select {
      appearance: none;
      background-color: transparent;
      background-position: right 24px center !important;
    }
  }

  label {
    font-size: 16px;
    margin-bottom: 12px;

    &.required {
      &::after {
        content: '*';
      }
    }
  }

  .was-validated .form-control:valid,
  .form-control.is-valid {
    border-color: #1c3fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231C3FFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");

    & + label {
      color: #1c3fff;
    }
  }

  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgb(28 59 255 / 25%);
  }

  button {
    margin-top: 8px;
    height: 48px;
  }

  .MuiSelect-selectMenu {
    min-height: 11px;
    padding: 18px 14px;
  }

  .MuiSvgIcon-root {
    font-size: 24px;
  }

  .MuiInputLabel-outlined {
    transform: translate(16px, 14px) scale(1);
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: #1c3fff;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ced4da;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 18px;
    font-family: 'CircularXX', sans-serif;
  }

  .MuiFormLabel-root {
    color: #bdbdbd;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: ${colors.base.white};
    padding-right: 6px;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

export const SubscribeContainer = styled.div`
  background: var(--primary-color);
  border-radius: 10px;
  padding: 48px;
  color: ${colors.base.white};
  overflow: hidden;

  h2 {
    ${font('display', 'md', '700')}
    color: ${colors.base.white};
    margin: 0;

    ${atMinWidth.sm`
      ${font('display', 'lg', '700')}
    `}
  }

  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 1199px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .form-header {
      max-width: 450px;

      @media (max-width: 1199px) {
        max-width: 85%;
        margin-bottom: 12px;
      }

      h3 {
        font-weight: bold;
        font-size: 35.16px;
        line-height: 110%;
        letter-spacing: -0.3px;
        color: #ffffff;
      }
    }

    .form-content {
      width: 417px;

      @media (max-width: 1199px) {
        width: 480px;
      }

      @media (max-width: 575px) {
        width: 100%;
      }

      .form-inputs {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }

        input {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #ffffff;
          width: 260px;

          @media (max-width: 1199px) {
            width: 300px;
          }

          @media (max-width: 575px) {
            width: 100%;
          }
        }

        .btn {
          font-weight: bold;
          font-size: 16px;
          line-height: 110%;
          letter-spacing: -0.16px;
          color: #1c3eff;
          width: 122px;
          margin: 0;
          padding: 0;

          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
    }
  }

  h3 {
    ${font('display', 'lg', '700')}
    margin: 0;
    color: ${colors.base.white};

    &.thank-header {
      margin-bottom: 12px;
    }
  }

  p {
    margin: 0 auto;
    max-width: 512px;
  }

  .btn-primary {
    background: ${colors.base.white};
    color: var(--primary-color);

    &:hover {
      color: #000;
      background-color: #fff !important;
    }
  }

  .form-wrapper {
    @media (max-width: 400px) {
      flex-wrap: wrap;

      button {
        margin-left: 0 !important;
        width: 100%;
        margin-top: 8px !important;
      }
    }
  }
`;
